<template>
  <v-container fluid class="mx-auto">
    <side-menu :main-page="$route.name" />
    <div style="padding: 0 139px 150px 395px">
      <div style="max-width: 740px" class="mx-auto pt-11">
        <div class="d-flex align-center mb-3">
          <v-avatar color="indigo" size="48" class="mr-2">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-avatar>
          <!-- <v-btn color="#8F5F98" text>変更</v-btn> -->
        </div>

        <v-form ref="form">
          <v-text-field
            label="Display Name"
            placeholder="Taro Yamada"
            hide-details="auto"
            class="mb-4"
            v-model="account.name"
          ></v-text-field>

          <v-text-field
            label="E-mail"
            placeholder="xxx@xxx.xxx"
            hide-details="auto"
            v-model="account.mail"
            :rules="[rules.email]"
          ></v-text-field>
          <p class="grey--text caption">
            {{ getWords("account_message_email") }}
          </p>

          <v-text-field
            label="NEW PASSWORD"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            hide-details="auto"
            @click:append="show1 = !show1"
            :rules="[rules.password]"
            v-model="account.pass"
          ></v-text-field>
          <p class="grey--text caption">
            {{ getWords("account_message_pass1") }}
          </p>
          <v-text-field
            label="CURRENT PASSWORD"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            hide-details="auto"
            @click:append="show2 = !show2"
            :rules="[rules.password]"
            v-model="currentPass"
          ></v-text-field>
          <p class="grey--text caption">
            {{ getWords("account_message_pass2") }}
          </p>
          <p class="darkgrey--text caption mb-0">API KEY</p>
          <p class="text-subtitle-1">
            {{ account.apikey }}
            <v-btn small outlined color="#5C5C5C" @click="reGenerateKey">
              {{ getWords("account_message_apikey") }}
            </v-btn>
          </p>
        </v-form>
        <p v-if="errmsg !== ''" class="red--text caption mt-4 ml-2">
          {{ errmsg }}
        </p>
        <div class="d-flex justify-center mt-16">
          <v-btn
            large
            outlined
            color="#5C5C5C"
            :disabled="isProcessing"
            :loading="isProcessing"
            @click="handleChange"
          >
            SAVE
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import SideMenu from "../../components/SideMenu";
import { auth, getLoginUserInfo, updateUser } from "../../plugins/firebase";
import generateApiKey from "generate-api-key";
import words from "../../assets/localewords.json";

export default {
  name: "Account",
  components: { SideMenu },
  data: () => ({
    account: {
      pass: "",
      name: "",
      mail: "",
      apikey: "",
    },
    currentPass: "",
    isProcessing: false,
    show1: false,
    show2: false,
    rules: {
      required: (v) => (v && !!v) || "Required.",
      email: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Invalid email",
      password: (v) => /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/.test(v) || "Requires at least 8 letters it contains at least one 'Uppercase', one 'Lowercase', one 'Number'.",
    },
    errmsg: "",
    userInfo: {},
  }),
  created() {
    this.initUser();
  },
  computed: {
    getUser() {
      return this.$store.state.user;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    getUser: function () {
      this.initUser();
    },
  },
  methods: {
    initUser: async function () {
      const user = this.getUser;
      this.account.name = user.displayName;
      this.account.mail = user.email;
      this.userInfo = await getLoginUserInfo();
      this.account.apikey = this.userInfo.apikey;
      this.isProcessing = false;
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    reGenerateKey: function () {
      this.account.apikey = generateApiKey({
        method: "string",
        pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        length: 64,
      });
    },
    handleChange: async function () {
      this.errmsg = "";
      if (this.rules.password(this.currentPass) !== true) {
        this.errmsg = "Current password is required.";
        return;
      }
      this.isProcessing = true;
      const user = auth.currentUser;
      const updateData = {};
      if (this.account.name !== user.displayName && this.account.name !== "") {
        updateData["displayName"] = this.account.name;
      }
      if (this.rules.email(this.account.mail) === true && this.account.mail !== user.email) {
        updateData["email"] = this.account.mail;
      }
      if (this.rules.password(this.account.pass) === true) {
        updateData["password"] = this.account.pass;
      }
      if (this.account.apikey !== this.userInfo.apikey && this.account.apikey !== "") {
        updateData["apiKey"] = this.account.apikey;
      }
      updateData["uid"] = this.userInfo.uid;
      const self = this;
      try {
        await updateUser(updateData);
        // const res = await updateUser(updateData)
        // console.log(res)
        // await user.reload();
        // reloadだとclaimsがうまく取れない。もう一度SignInしてリフレッシュ
        auth
          .signInWithEmailAndPassword(user.email, this.currentPass)
          .then(async function (userCredential) {
            self.$store.dispatch("commitLogin", {
              displayName: userCredential.user.displayName,
              email: userCredential.user.email,
            });
            self.initUser();
          });
      } catch (err) {
        this.errmsg = JSON.stringify(err);
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style scoped></style>
